<template>
  <div class="result-box">
    <img class="img-result" src="@/assets/images/success.png" width="134" />
    <div class="margin-top-28 status">{{$t('message.result.success')}}</div>
    <div v-if="redirct" class="margin-top-36 jump" v-html="$t('message.result.jump', { time: time })"></div>
  </div>
</template>
<script>
import { getStatus, getBrandInfo } from '@/api/checkout';
import { deviceFingerprintManager } from '@/plugins/deviceFingerprintManager';
import { sendGaResultEvent } from '@/utils/tools/ga';
import { setRedirectFlag, hasRedirect } from '@/utils/tools/utils';

export default {
  data() {
    return {
      redirct: '',
      time: 3,
      timer: null,
      key: '',
      orderInfo: null,
    };
  },
  created() {
    this.key = this.$route.query.key ? this.$route.query.key : this.$route.params.id;
    getBrandInfo(this.key).then((res) => {
      if (res.respCode === '20000' && res.data) {
        this.$store.dispatch('app/setBrandInfo', res.data);
      }
    }).catch(() => ({}));
    getStatus(this.key).then((res) => {
      if (res.respCode === '20000') {
        this.orderInfo = res.data;
        const hRedirect = hasRedirect(this.key);
        if (!hRedirect) {
          this.redirct = res.data.redirectUrl;
          this.timerStart();
        }
      }
      this.sendGaEvent('visit_payment_result_page');
      this.sendRiskDeviceFingerPrint('visit_payment_result_page');
    }).catch(() => {
      this.sendGaEvent('visit_payment_result_page');
      this.sendRiskDeviceFingerPrint('visit_payment_result_page');
    });
    window.addEventListener('beforeunload', this.beforeUnloadEvent);
  },
  beforeDestroy() {
    this.sendGaEvent('exit_payment_result_page');
    this.sendRiskDeviceFingerPrint('exit_payment_result_page');
    window.removeEventListener('beforeunload', this.beforeUnloadEvent);
  },
  methods: {
    timerStart() {
      this.timer = setInterval(() => {
        this.time -= 1;
        if (this.time <= 0) {
          if (this.redirct) {
            setRedirectFlag(this.key);
            window.location.href = this.redirct;
          }
          clearInterval(this.timer);
        }
      }, 1000);
    },
    sendGaEvent(eventName) {
      sendGaResultEvent(this.$route.query.type, this.key, this.orderInfo, eventName);
    },
    beforeUnloadEvent() {
      this.sendGaEvent('exit_payment_result_page');
      this.sendRiskDeviceFingerPrint('exit_payment_result_page');
    },
    sendRiskDeviceFingerPrint(event) {
      const param = {
        m: this.$route.query.merchantTxnId || '',
        timeStamp: Date.now(),
        e: event,
      };
      deviceFingerprintManager.send(param);
    },
  },
};
</script>
